.articles {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  justify-items: center;
}

.article {
  display: grid;
  grid-template-columns: 50px 1fr 50px;
  text-align: center;
}

.article.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9); /* 20% transparent background */
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer; /* Pointer cursor over the background */
}

.article.fullscreen .article-page {
  width: auto;
}

.article.fullscreen .article-page img {
  height: 100vh;
  width: auto;
}

.article.fullscreen .nav {
  width: 50px;
}

.article.fullscreen .close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  z-index: 10001;
  width: 50px; 
  height: 50px; 
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0 !important;
}

.fullscreen-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  color: white;
  font-size: 24px;
  cursor: pointer;
  padding: 5px;
  z-index: 9999;
}

.article button.nav {    
  display: flex;
  flex-direction: column;
  justify-content: center; /* Vertically center the contents */
  height: 100%;
  background-color: var(--dark-grey);
  /* background-color: red; */
  margin: 0 !important;
  padding: 0;
  border-radius: 0;
}

.article button.nav:disabled {
  cursor: not-allowed;
  opacity: 0.5; /* Reduce the opacity to visually indicate the button is disabled */
}

.article button.nav:disabled:hover {
  background-color: var(--dark-grey) !important; 
}

.article button.open {
  height: auto;
  margin: 0 !important;
  padding: 0;
  border-radius: 0;
  max-width: 100%;
}

.article-next, .article-prev {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Vertically center the contents */
  align-items: center; /* Horizontally center the contents */
  height: 100%;
  width: 100%;
}

.article-page {
  position: relative; /* Add this to position the fullscreen icon relative to the article-page */
  text-align: center;
  width: 100%;
  line-height: 0;
}

.article-page img {
  margin: 0;
}

.article-page.hide {
  display: none;
}

@media (max-width: 992px) {
  .articles {
    grid-template-columns: 1fr;
  }

  .article {
    grid-template-columns: 40px 1fr 40px;
  }
}

@media (max-width: 740px) {
  .article {
    grid-template-columns: 30px 1fr 30px;
  }

  .article.fullscreen .article-page img {
    height: auto;
    width: 100%;
  }
}

@media (max-width: 600px) {
  .article {
    grid-template-columns: 20px 1fr 20px;
  }
}