@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400italic,600,600italic,800,800italic");

:root {
    /* --primary: #FBAE54; */
    /* --primary: #A27D5F; */
    --primary: #9f3d1d;
    --border-color: #d0ccd0;
    --correct:  #35605a;
    --signal: #dd2913;
    --light-grey: #F4F4F4;
    --grey: #262626;
    --dark-grey: #1d242a;
    --input-background: #90909040;
    --link-dark: #9e9eff;
    --link-dark-hover: #ff9e9e;
    --link-dark-visited:#d0adf0 ;
}

[data-theme="light"] {
  --background: white;
  --text-color: #4a3233;
}

[data-theme="dark"] {
  --background: #262626;
  --text-color: #F4F4F4;
}

html {
    scroll-behavior: smooth;
    background-color: var(--background);
}

body, 
textarea, 
input, 
select {
    font-family: Open Sans, Helvetica, sans-serif;
    letter-spacing: .075em;
    color: var(--text-color);
    font-size: 1.25em;
    font-weight: 300;
    line-height: 1.65em;
}

option {
  color: var(--text-color);
}

[data-theme='dark'] option {
  color: var(--dark-grey);
}

p,
li,
label,
input,
select,
textarea {
    font-size: 1em;
}

select {
    width: 100%;
    cursor: pointer;
}

.select-container {
    position: relative; /* Positioning context for the caret */
    width: 100%;
}

/* Custom caret */
.select-caret {
    position: absolute; /* Positioning the caret */
    right: 10px; 
    top: 50%; 
    transform: translateY(-50%); /* Adjust for centering */
    pointer-events: none; /* Prevent mouse events on the caret */
    width: 0;
    height: 0;
    border-left: 5px solid transparent; /* Left part of the caret */
    border-right: 5px solid transparent; /* Right part of the caret */
    border-top: 5px solid #333; /* Color of the caret */
    margin-top: 0;
}

ul, ol {
    margin-block-start: 1em;
    padding-inline-start: 40px;
}

h2, h3 {
    color: var(--primary);
    text-transform: uppercase;
    text-align: center;
}

h2 {
    font-size: 2.4em;
    padding-top:50px;
    line-height: normal;
}

h3 {
    font-size: 1.6em;
    margin-top: 20px;
}

hr {
    border: 0;
    border-top: 2px solid var(--primary);
    margin: 40px auto;
    width: 25%;
    min-width: 200px;
}

@media only screen and (max-width: 768px){
    h3 {
        font-size: 1.4em;
    }
}

@media only screen and (max-width: 640px){
    h3 {
        font-size: 1.2em;
    }
}

h4 {
    font-style: italic;
}

p {
    margin: 10px 0;
}

img {
    margin: 10px 0;
}

a {
    line-height: normal;
}

a:hover, a:active {
  color: var(--signal) !important;
}

[data-theme='dark'] a {
  color: var(--link-dark);
}

[data-theme='dark'] a:hover, [data-theme='dark'] a:active {
  color: var(--link-dark-hover) !important;
}

[data-theme='dark'] a:visited {
  color: var(--link-dark-visited);
}

div.image-centre {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

div.image-centre a.buttonstandard {
    margin: 20px 0;
    display: inline-block;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.wrap {
    margin: 0 auto;
}


@media only screen and (min-width: 1200px) {
  .wrap {
      width: 90%;
  }
}

@media only screen and (min-width: 1400px) {
  .wrap {
      width: 80%;
  }
}

.main {
    padding: 0% 2% 4% 2%;
}

a.image {
    text-decoration: none;
    border: none;
}

.slider_text a.image {
    position: absolute;
    width: 120px;
    transform: translateX(-50%);
    width: 150px;
}

.col-6 {
    width: 50%;
}

.col-3 {
    width: 25%;
}

.col-2 {
    width: 16.6666667%;
}

input[type=email],
input[type=password],
input[type=text],
select,
textarea {
    -webkit-appearance: none;
    appearance: none;
    background-color: var(--input-background);
    border: none;
    border-radius: 3px;
    color: inherit;
    display: block;
    outline: 0;
    padding: 0 1em;
    text-decoration: none;
}

input[type=email],
input[type=password],
input[type=text],
select {
    height: 2.75em;
}

input[type=email],
input[type=password],
input[type=text],
textarea {
    width: calc(100% - 2em);
}

input[type=button],
input[type=reset],
input[type=submit],
button,
a.buttonstandard {
    appearance: none;
    border: 0;
    border-radius: 3px;
    box-shadow: inset 0 0 0 2px #fff;
    cursor: pointer;
    display: inline-block;
    font-size: .8em;
    font-weight: 600;
    height: 3.125em;
    letter-spacing: .225em;
    line-height: 3.125em;
    max-width: 30em;
    overflow: hidden;
    padding: 0 2.75em;
    text-align: center;
    text-decoration: none;
    text-overflow: ellipsis;
    text-transform: uppercase;
    transition: background-color .2s ease-in-out, color .2s ease-in-out;
    white-space: nowrap;
    background-color: var(--primary);
    box-shadow: none !important;
    color: #fff !important;
}

input[type=button]:hover,
input[type=reset]:hover,
input[type=submit]:hover,
button:hover,
a.buttonstandard:hover { 
    background-color: var(--correct) !important;
}

input[type=submit]:disabled,
a.buttonstandard:disabled {
    background-color: var(--input-background);
    cursor: wait;
}

input[type="text"]:invalid,
input[type="password"]:invalid,
input[type="email"]:invalid,
select:invalid,
textarea:invalid, 
textarea.error,
input.error {
    box-shadow: 0 0 0 2px var(--signal);
}

input[type="checkbox"] {
    display: none; /* Hide the default checkbox */
}

.custom-checkbox {
    width: 25px;
    min-width: 25px;
    height: 25px;
    border-radius: 4px;
    position: relative;
    margin-right: 10px;
    cursor: pointer;
    background-color: var(--input-background);
    display: inline-block;
}

input[type="checkbox"]:checked + .custom-checkbox::after {
    content: '';
    position: absolute;
    border: solid var(--text-color);
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.checkbox-container {
    display: flex;
    align-items: center;
}

button:not(:last-child) {
    margin-right: 10px; 
}

form div {
    margin-top: 20px;
}

.line-height-1 {
    line-height: 1em;
}

.small-text {
    font-size: 0.8em;
}

.block {
    display: block;
}

.grey {
	background: var(--light-grey);
}

.error {
    color: var(--signal);
}

.slider_text div a, .slider_text div a:visited {
  color: var(--light-grey) !important;
}

.slider_text div a:active, .slider_text div a:hover {
  color: var(--primary) !important;
}

@media screen and (max-width: 576px) {
    .col-12-xsmall {
        width: 100%;
    }
}